import instance from './API';
import { trim } from 'lodash';

const memberAPI = {
  getMember: (userId) => {
    return instance.get(`/Member/${userId}`);
  },
};

export default memberAPI;
