import React, { useEffect, useState } from 'react';
import RestaurantLogo from '../../images/restaurant_logo.svg';
import BonusQrCodeReader from '../../components/Bonus/BonusQrCodeReader';
import '../../style/bonus.scss';
import bonusAPI from '../../middleware/bonusAPI';
import BackBtn from '../../components/Bonus/BackBtn';
import GuestAmount from '../../components/Bonus/GuestAmount';
import AskUseBonus from '../../components/Bonus/AskUseBonus';
import Swal from 'sweetalert2';
import { trim } from 'lodash';
import { alertError } from '../../Util/Util';
import RewardBonus from '../../components/Bonus/RewardBonus';
import GuestCardPunch from '../GuestCards/GuestCardPunch';
import ProductPackageBuy from '../ProductPackages/ProductPackageBuy';
import ProductPackageCancel from '../ProductPackages/ProductPackageCancel';
import SelectMode from '../../components/Bonus/SelectMode';

const Guest = () => {
  const [pageStep, setPageStep] = useState('scanQrcode');
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [guestMember, setGuestMember] = useState();
  const [rewardBonus, setRewardBonus] = useState();
  const [calculateBonus, setCalculateBonus] = useState();
  const [useBonusResult, setUseBonusResult] = useState();
  const [isUseServiceCharge, setIsUseServiceCharge] = useState(false);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [cancelReScanReturnPage, setCancelReScanReturnPage] = useState();
  const [scanData, setScanData] = useState();
  const [memberMode, setMemberMode] = useState(); // 會員使用情境 bonus 紅利 guestCard 共享空間

  useEffect(() => {
    if (calculateBonus) {
      if (calculateBonus.bonusCalculate === 0) {
        setPageStep('addBonus');
      } else {
        setPageStep('useBonus');
      }
    }
  }, [calculateBonus]);

  useEffect(() => {
    if (useBonusResult) {
      setPageStep('addBonus');
    }
  }, [useBonusResult]);

  useEffect(() => {
    if (rewardBonus) {
      setPageStep('reward');
    }
  }, [rewardBonus]);

  useEffect(() => {
    if (amount) {
      const serviceCharge = Math.round(amount * 0.1);

      setServiceCharge(serviceCharge);
    } else {
      setServiceCharge(0);
    }
  }, [amount]);

  useEffect(() => {
    if (memberMode === 'guestCard' && pageStep !== 'gusetCard') {
      setPageStep('guestCard');
    }
  }, [memberMode]);

  const verifyQrcodeData = (qrcodeData, errorTrigger) => {
    const qrcode = trim(qrcodeData);
    const worker = qrcode.startsWith('GC_')
      ? 'guestCard'
      : qrcode.startsWith('PACKAGE_')
      ? 'productPackage'
      : 'member';

    setScanData(qrcode);

    // 會員紅利點數流程
    if (worker === 'member') {
      setIsLoading(true);

      return bonusAPI
        .verifyQrcode(qrcodeData)
        .then(
          (res) => {
            if (!guestMember) {
              setGuestMember({ ...res });

              // 掃描抵用券的話不用選擇模式
              if (res.productInfoList && res.productInfoList.length > 0) {
                setMemberMode('bonus');
              }
            } else if (guestMember.contact !== res.contact) {
              Swal.fire({
                title: '不同會員無法共用',
                text: '如需要使用別的會員，請回首頁重新操作',
              });
            } else {
              const oldProductInfoList = guestMember.productInfoList
                ? [...guestMember.productInfoList]
                : [];
              const newProductInfoList = res.productInfoList
                ? oldProductInfoList.concat(res.productInfoList)
                : oldProductInfoList;

              setGuestMember({
                ...res,
                productInfoList: newProductInfoList,
              });
            }

            setPageStep(
              cancelReScanReturnPage ? cancelReScanReturnPage : 'checkBonus',
            );
          },
          (error) => {
            alertError(error, '無法取的會員資訊', errorTrigger);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }

    // 共享空間
    if (worker === 'guestCard') {
      setPageStep('guestCard');
    }

    // 套券 productPackage
    if (worker === 'productPackage') {
      if (qrcode.startsWith('PACKAGE_BUY')) {
        setPageStep('productPackageBuy');
      } else if (qrcode.startsWith('PACKAGE_CANCEL')) {
        setPageStep('productPackageCancel');
      }
    }
  };

  const checkBonus = () => {
    if (trim(amount) === '') {
      Swal.fire({
        icon: 'info',
        title: '請填寫消費金額',
      });

      return false;
    }

    setIsLoading(true);

    bonusAPI
      .calculateBonus(guestMember.contact, amount)
      .then(
        (res) => {
          setCalculateBonus(res);
        },
        (error) => {
          alertError(error, '無法取的可折扣的紅利點數資訊');
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const addBonus = () => {
    if (trim(amount) === '') {
      Swal.fire({
        icon: 'info',
        title: '請填寫發票金額',
      });

      return false;
    }

    setIsLoading(true);

    const totalAmount = isUseServiceCharge
      ? parseInt(amount, 10) + parseInt(serviceCharge, 10)
      : parseInt(amount, 10);

    bonusAPI
      .accumulatedPoints(guestMember.contact, totalAmount)
      .then(
        (res) => {
          setRewardBonus(res);
        },
        (error) => {
          alertError(error, '增加紅利失敗');
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const bonusUse = () => {
    setIsLoading(true);

    bonusAPI
      .redeemBonus(guestMember.contact, amount, calculateBonus.bonusCalculate)
      .then(
        (res) => {
          setAmount(
            parseInt(amount, 10) - parseInt(calculateBonus.bonusCalculate, 10),
          );
          setUseBonusResult({
            use: calculateBonus.bonusCalculate,
            remaining: res,
          });
        },
        (error) => {
          alertError(error, '折扣紅利點數失敗');
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const bonusNoUse = () => {
    setPageStep('addBonus');
  };

  const switchServiceCharge = () => {
    setIsUseServiceCharge(!isUseServiceCharge);
  };

  const reScanQrcode = () => {
    setCancelReScanReturnPage(pageStep);
    setPageStep('scanQrcode');
  };

  const cancelReScanQrcode = () => {
    setPageStep(cancelReScanReturnPage);
  };

  const backAction = () => {
    setGuestMember();
    setAmount('');
    setMemberMode(undefined);
  };

  return (
    <div className="bonus">
      <div className="bonus__logo">
        <img src={RestaurantLogo} />
        <div className="bonus__logo-btn">
          <BackBtn backAction={backAction} />
        </div>
      </div>

      {pageStep === 'scanQrcode' && (
        <BonusQrCodeReader
          verifyQrcodeData={verifyQrcodeData}
          cancelReScanQrcode={guestMember ? cancelReScanQrcode : undefined}
          isLoading={isLoading}
        />
      )}

      {pageStep === 'checkBonus' && !memberMode && (
        <SelectMode selectMode={(mode) => setMemberMode(mode)} />
      )}

      {((pageStep === 'checkBonus' && guestMember) ||
        pageStep === 'addBonus') &&
        memberMode === 'bonus' && (
          <GuestAmount
            guestMember={guestMember}
            amount={amount}
            setAmount={setAmount}
            checkBonus={checkBonus}
            addBonus={addBonus}
            isLoading={isLoading}
            pageStep={pageStep}
            useBonusResult={useBonusResult}
            calculateBonus={calculateBonus}
            serviceCharge={serviceCharge}
            isUseServiceCharge={isUseServiceCharge}
            switchServiceCharge={switchServiceCharge}
            reScanQrcode={reScanQrcode}
          />
        )}

      {pageStep === 'useBonus' && (
        <AskUseBonus
          guestMember={guestMember}
          calculateBonus={calculateBonus}
          amount={amount}
          bonusUse={bonusUse}
          bonusNoUse={bonusNoUse}
          isLoading={isLoading}
        />
      )}

      {pageStep === 'reward' && <RewardBonus rewardBonus={rewardBonus} />}

      {pageStep === 'guestCard' && (
        <GuestCardPunch
          qrcode={memberMode === 'guestCard' ? guestMember.userId : scanData}
        />
      )}

      {pageStep === 'productPackageBuy' && (
        <ProductPackageBuy scanData={scanData} />
      )}

      {pageStep === 'productPackageCancel' && (
        <ProductPackageCancel scanData={scanData} />
      )}
    </div>
  );
};

export default Guest;
