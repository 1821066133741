import React, { useEffect, useState } from 'react';
import RestaurantLogo from '../../images/restaurant_logo.svg';
import BackBtn from '../../components/Bonus/BackBtn';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
} from '@material-ui/core';
import { alertError } from '../../Util/Util';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import guestCardAPI from '../../middleware/guestCardAPI';
import { LIST_PAGE_COUNT } from '../../data/common';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import PaymentRoundedIcon from '@material-ui/icons/PaymentRounded';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.2rem',
    fontWeight: '600',
    textAlign: 'left',
    padding: theme.spacing(1, 0, 0),
  },
  cardList: {
    backgroundColor: '#FFF',
    margin: theme.spacing(2, 'auto'),
  },
  useTime: {
    letterSpacing: '1px',
  },
  pagination: {
    justifyContent: 'center',
    margin: theme.spacing(1, 'auto'),
  },
}));

const GuestCardInUseList = (props) => {
  const classes = useStyles();
  const [touchRefresh, setTouchRefresh] = useState(false);
  const [inUseCards, setInUseCards] = useState();
  const [inUseCardTotal, setInUseCardTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getInUseCards();
  }, [touchRefresh, page]);

  const getInUseCards = () => {
    setIsLoading(true);

    guestCardAPI
      .getGuestCardHistory(
        {
          status: 1,
        },
        LIST_PAGE_COUNT,
        page,
      )
      .then(
        (rs) => {
          setInUseCards(rs.historyList);
          setInUseCardTotal(rs.totalCount);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleRefresh = () => {
    setTouchRefresh(!touchRefresh);
  };

  return (
    <div className="bonus">
      <div className="bonus__logo">
        <img src={RestaurantLogo} />
        <div className="bonus__logo-btn">
          <BackBtn />
        </div>
      </div>

      {inUseCardTotal <= 0 && !isLoading && (
        <div className="confirm-code__empty">無使用中的卡片</div>
      )}

      {inUseCardTotal > 0 && inUseCards && (
        <>
          <div className={classes.title}>目前啟用張數 {inUseCardTotal}</div>

          <div className={classes.cardList}>
            <List>
              {map(inUseCards, (inUseCard) => {
                return (
                  <ListItem key={inUseCard.id} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar>
                        {inUseCard.cardInfo ? (
                          <PaymentRoundedIcon />
                        ) : (
                          <FaceRoundedIcon />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${
                        inUseCard.cardInfo
                          ? inUseCard.cardInfo.code
                          : inUseCard.userInfo.realName
                      }`}
                      secondary={
                        <span className={classes.useTime}>
                          {moment(inUseCard.signInTime).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )}
                        </span>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>

            <Box display="flex">
              <Pagination
                className={classes.pagination}
                count={Math.ceil(inUseCardTotal / LIST_PAGE_COUNT)}
                color="primary"
                disabled={isLoading}
                page={page}
                onChange={(e, page) => {
                  setPage(page);
                }}
              />
            </Box>
          </div>
        </>
      )}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        startIcon={<RefreshRoundedIcon />}
        disabled={isLoading}
        onClick={handleRefresh}>
        重新取得資訊
      </Button>
    </div>
  );
};

export default GuestCardInUseList;
