import React from 'react';
import { Button, TableCell, TableRow, Chip } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { formatNumberComma, seconds2time } from '../../Util/Util';
import { trim } from 'lodash';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import PaymentRoundedIcon from '@material-ui/icons/PaymentRounded';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';

const GuestCardHistoryTableRow = (props) => {
  const { history, openDetail } = props;

  const handleOpenDetail = () => {
    openDetail(history);
  };

  return (
    <TableRow>
      <TableCell component="th">
        {history.cardType && (
          <img src={history.cardType.frontImgUrl} style={{ width: '60px' }} />
        )}
      </TableCell>
      <TableCell component="th">
        {history.cardType && history.cardType.name}
      </TableCell>
      <TableCell>
        <Chip
          color={history.useTarget === 'user' ? 'secondary' : 'primary'}
          icon={
            history.useTarget === 'user' ? (
              <FaceRoundedIcon />
            ) : (
              <PaymentRoundedIcon />
            )
          }
          label={
            history.useTarget === 'user'
              ? `會員 ${history.userInfo.realName}`
              : `卡片 ${history.cardInfo.code}`
          }
        />
      </TableCell>
      <TableCell>{history.signInTime}</TableCell>
      <TableCell>{history.signOutTime}</TableCell>
      <TableCell>{seconds2time(history.useTimeSec, true)}</TableCell>
      <TableCell>
        <Chip
          color={history.historyStatus === 'bill' ? 'secondary' : 'primary'}
          icon={
            history.historyStatus === 'bill' ? (
              <ReceiptRoundedIcon />
            ) : (
              <TimerRoundedIcon />
            )
          }
          label={history.historyStatus === 'bill' ? '已結帳' : '使用中'}
        />
      </TableCell>
      <TableCell>
        {history.historyStatus === 'bill' &&
          trim(history.expenseAmount) !== '' && (
            <span>{formatNumberComma(history.expenseAmount)}</span>
          )}
      </TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          color="default"
          size="small"
          startIcon={<AssignmentIcon />}
          style={{ marginRight: '12px' }}
          onClick={handleOpenDetail}>
          檢視
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default GuestCardHistoryTableRow;
