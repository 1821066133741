import { Button } from '@material-ui/core';
import React from 'react';

const SelectMode = (props) => {
  const { selectMode } = props;

  const handleSelectBonusMode = () => {
    selectMode('bonus');
  };

  const handleSelectGuestCardMode = () => {
    selectMode('guestCard');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '36px',
        marginTop: '60px',
      }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSelectBonusMode}
        style={{
          height: '110px',
          width: '110px',
          fontWeight: '600',
          fontSize: '1.7rem',
        }}>
        紅利點數
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSelectGuestCardMode}
        style={{
          height: '110px',
          width: '110px',
          fontWeight: '600',
          fontSize: '1.7rem',
        }}>
        共享空間
      </Button>
    </div>
  );
};

export default SelectMode;
