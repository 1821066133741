import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { map } from 'lodash';
import TablePaginationActions from '../TablePaginationActions';
import { LIST_PAGE_COUNT } from '../../data/common';
import UserProductPackageOrderTableRow from './UserProductPackageOrderTableRow';

const UserProductPackageOrderTable = (props) => {
  const {
    userProductPackageOrders,
    totalCount,
    openDetail,
    page,
    setPage,
  } = props;

  return (
    <TableContainer component={Box}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>套券圖片</TableCell>
            <TableCell>套券名稱</TableCell>
            <TableCell>購買人</TableCell>
            <TableCell>銷售人員</TableCell>
            <TableCell>銷售日期</TableCell>
            <TableCell>狀態</TableCell>
            <TableCell>取消人員</TableCell>
            <TableCell>取消日期</TableCell>
            <TableCell>套券內容</TableCell>
            <TableCell align="right">功能</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {map(userProductPackageOrders, (userProductPackage) => (
            <UserProductPackageOrderTableRow
              key={userProductPackage.orderNumber}
              userProductPackage={userProductPackage}
              openDetail={openDetail}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[LIST_PAGE_COUNT]}
              colSpan={10}
              count={totalCount}
              rowsPerPage={LIST_PAGE_COUNT}
              page={page - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(event, currentPage) => {
                setPage(currentPage + 1);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default UserProductPackageOrderTable;
