import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { find, forEach, map, trim } from 'lodash';
import memberAPI from '../../middleware/memberAPI';
import { alertError } from '../../Util/Util';
import productPackageAPI from '../../middleware/productPackageAPI';
import Swal from 'sweetalert2';
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import RedeemRoundedIcon from '@material-ui/icons/RedeemRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import {
  UserBonusProductStatus,
  UserBonusProductStatusText,
} from '../../data/common';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
  },
  coverImg: {
    width: '150px',
    maxHeight: '200px',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  card: {
    margin: '12px 0',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '12px',
  },
  successCard: {
    margin: '12px 0',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '12px',
    color: '#83ad5d',
    fontSize: '2rem',
  },
  name: {
    fontSize: '2rem',
    fontWeight: '600',
  },
  groupInfo: {
    margin: '6px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '6px',
    padding: '6px',
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: '#eee',
    width: '100%',
  },
  groupCount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: '12px',
    margin: '6px auto',
  },
  count: {
    width: '60px',
  },
  countName: {
    width: '100%',
    fontSize: '1rem',
    fontWeight: '600',
    textAlign: 'left',
  },
  countGift: {
    fontSize: '0.8rem',
    fontWeight: '500',
    position: 'absolute',
    top: '0',
    right: '0',
    color: '#bdab34',
    borderRadius: '100%',
    width: '25px',
    height: '25px',
    textAlign: 'center',
    backgroundColor: '#fff',

    '& > *': {
      width: '19px',
    },
  },
  countTitle: {
    color: '#e56b6b',
  },
  countNumber: {
    fontSize: '1.2rem',
    fontWeight: '600',
    color: '#e56b6b',
  },
  userProductCards: {
    width: '100%',
  },
  userProductCard: {
    margin: '6px 0',
    display: 'flex',
    width: '100%',
    minHeight: '60px',
    border: '2px solid #eee',
    borderRadius: '6px',
    padding: '12px',
    justifyContent: 'space-between',
  },
  userProductCardUse: {
    backgroundColor: '#1e1918',
    color: '#fff',
  },
  userProductCardGiven: {
    backgroundColor: '#ffeded',
    color: '#8f8f8f',
  },
  userProductCardCancel: {
    border: '0',
  },
  userProductCardName: {
    textAlign: 'left',
    display: 'flex',
    gap: '6px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  userProductCardStatus: {
    fontSize: '2rem',
  },
  userProductCardTime: {
    fontSize: '0.8rem',
  },
}));

const ProductPackageCancel = (props) => {
  const classes = useStyles();
  const { scanData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [orderNumber, setOrderNumber] = useState();
  const [user, setUser] = useState();
  const [productPackage, setProductPackage] = useState();
  const [userProductPackages, setUserProductPackages] = useState();
  const [pageStep, setPageStep] = useState('check');
  const [cover, setCover] = useState();
  const [groupInfo, setGroupInfo] = useState();

  useEffect(() => {
    if (trim(scanData) !== '') {
      const [userId, orderNumber] = scanData.substring(15).split('_');
      setUserId(userId);
      setOrderNumber(orderNumber);
    } else {
      setUserId(undefined);
      setOrderNumber(undefined);
    }
  }, [scanData]);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);

      memberAPI
        .getMember(userId)
        .then(
          (rs) => {
            setUser(rs);
          },
          (error) => {
            alertError(error);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [userId]);

  useEffect(() => {
    if (orderNumber && userId) {
      setIsLoading(true);

      productPackageAPI
        .getUserProductPackage(orderNumber, userId)
        .then(
          (rs) => {
            setUserProductPackages(rs.packageDetail);
            setProductPackage(rs.packageInfo);
          },
          (error) => {
            alertError(error);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [orderNumber, userId]);

  useEffect(() => {
    if (productPackage) {
      const newCover = find(productPackage.packageProductImageList, (image) => {
        return image.isCover === true;
      });

      setCover(newCover ? newCover : undefined);
    }
  }, [productPackage]);

  useEffect(() => {
    if (userProductPackages) {
      let newGroupInfo = {};

      forEach(userProductPackages, (userProductPackage) => {
        const key =
          userProductPackage.isGift === true
            ? '1'
            : '0' + '_' + userProductPackage.bonusProductId;

        if (!newGroupInfo[key]) {
          newGroupInfo[key] = {
            isGift: userProductPackage.isGift,
            use: 0,
            unUse: 0,
            given: 0,
            total: 0,
            name: userProductPackage.productInfo.name,
            userBonusProducts: [],
          };
        }

        if (
          parseInt(userProductPackage.status, 10) ===
          parseInt(UserBonusProductStatus.UN_USE, 10)
        ) {
          newGroupInfo[key].unUse = newGroupInfo[key].unUse + 1;
        }

        if (
          parseInt(userProductPackage.status, 10) ===
          parseInt(UserBonusProductStatus.USED, 10)
        ) {
          newGroupInfo[key].use = newGroupInfo[key].use + 1;
        }

        if (
          parseInt(userProductPackage.status, 10) ===
          parseInt(UserBonusProductStatus.GIFTED, 10)
        ) {
          newGroupInfo[key].given = newGroupInfo[key].given + 1;
        }

        newGroupInfo[key].total = newGroupInfo[key].total + 1;

        newGroupInfo[key].userBonusProducts.push(userProductPackage);
      });

      setGroupInfo(newGroupInfo);
    }
  }, [userProductPackages]);

  const handleCancelPackage = () => {
    if (trim(orderNumber) === '' || trim(userId) === '') {
      Swal.fire({
        title: '無法取消套券',
        icon: 'error',
        text: '缺少訂單編號 或 使用者 ID',
      });

      return false;
    }

    Swal.fire({
      title: '再次確認取消套券',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '確認',
    }).then((rs) => {
      if (rs.value) {
        setIsLoading(true);

        productPackageAPI
          .cancelUserProductPackage(orderNumber, userId)
          .then(
            () => {
              setPageStep('done');
            },
            (error) => {
              alertError(error);
            },
          )
          .then(() => {
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <div className={classes.root}>
      {pageStep === 'done' && (
        <div className={classes.successCard}>
          <CheckCircleOutlineRoundedIcon />
          取消完成
        </div>
      )}

      {pageStep === 'check' && (
        <>
          {productPackage && (
            <div className={classes.card}>
              {cover && (
                <img src={cover.imageUrl} className={classes.coverImg} />
              )}

              <div className={classes.name}>{productPackage.name}</div>
            </div>
          )}

          <Divider variant="fullWidth" />

          {user && (
            <div className={classes.card}>
              <div className={classes.name}>{user.realName}</div>
              <div>
                {trim(user.cellPhone) !== '' ? user.cellPhone : user.email}
              </div>
            </div>
          )}

          <Divider variant="fullWidth" />

          {groupInfo && (
            <div className={classes.card}>
              {map(groupInfo, (info, infoKey) => {
                return (
                  <div key={infoKey} className={classes.groupInfo}>
                    <div className={classes.countName}>
                      {info.name} X {info.total}
                      {info.isGift && (
                        <span className={classes.countGift}>
                          <RedeemRoundedIcon />
                        </span>
                      )}
                    </div>
                    <div className={classes.groupCount}>
                      <div className={classes.count}>
                        <div className={classes.countNumber}>{info.use}</div>
                        <div className={classes.countTitle}>己使用</div>
                      </div>
                      <div className={classes.count}>
                        <div className={classes.countNumber}>{info.given}</div>
                        <div className={classes.countTitle}>己贈送</div>
                      </div>
                      <div className={classes.count}>
                        <div
                          className={classes.countNumber}
                          style={{ color: '#000' }}>
                          {info.unUse}
                        </div>
                        <div className={classes.countTitle}>未使用</div>
                      </div>
                    </div>

                    <Accordion style={{ width: '100%' }}>
                      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                        <Typography>詳細</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.userProductCards}>
                          {map(info.userBonusProducts, (userBonusProduct) => {
                            const productStatus =
                              userBonusProduct.status ===
                              UserBonusProductStatus.USED
                                ? 'use'
                                : userBonusProduct.status ===
                                  UserBonusProductStatus.GIFTED
                                ? 'given'
                                : userBonusProduct.status ===
                                  UserBonusProductStatus.CANCEL
                                ? 'cancel'
                                : 'unUse';

                            let cardClassName = classes.userProductCard;

                            if (productStatus === 'use') {
                              cardClassName += ' ' + classes.userProductCardUse;
                            }

                            if (productStatus === 'given') {
                              cardClassName +=
                                ' ' + classes.userProductCardGiven;
                            }

                            if (productStatus === 'cancel') {
                              cardClassName +=
                                ' ' + classes.userProductCardCancel;
                            }

                            return (
                              <div
                                key={userBonusProduct.id}
                                className={cardClassName}>
                                <div className={classes.userProductCardName}>
                                  {userBonusProduct.productInfo.name}
                                  <div className={classes.userProductCardTime}>
                                    發券時間{' '}
                                    {moment(
                                      userBonusProduct.createdDate,
                                    ).format('YYYY-MM-DD')}
                                  </div>
                                  {productStatus === 'use' && (
                                    <div
                                      className={classes.userProductCardTime}>
                                      使用時間{' '}
                                      {moment(userBonusProduct.useTime).format(
                                        'YYYY-MM-DD HH:mm:ss',
                                      )}
                                    </div>
                                  )}
                                  {productStatus === 'given' && (
                                    <div
                                      className={classes.userProductCardTime}>
                                      贈送時間{' '}
                                      {moment(
                                        userBonusProduct.modifiedDate,
                                      ).format('YYYY-MM-DD HH:mm:ss')}
                                    </div>
                                  )}
                                  {productStatus === 'cancel' && (
                                    <div
                                      className={classes.userProductCardTime}>
                                      取消時間{' '}
                                      {moment(
                                        userBonusProduct.modifiedDate,
                                      ).format('YYYY-MM-DD HH:mm:ss')}
                                    </div>
                                  )}
                                </div>
                                <div className={classes.userProductCardStatus}>
                                  {
                                    UserBonusProductStatusText[
                                      userBonusProduct.status
                                    ]
                                  }
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          )}

          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            startIcon={<HowToRegRoundedIcon />}
            disabled={isLoading}
            onClick={handleCancelPackage}>
            套券退款
          </Button>
        </>
      )}
    </div>
  );
};

export default ProductPackageCancel;
