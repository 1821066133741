import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Drawer,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import { LIST_PAGE_COUNT } from '../../data/common';
import { trim } from 'lodash';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import reservationAPI from '../../middleware/reservationAPI';
import UserReservationTable from '../../components/Reservations/UserReservationTable';
import UserReservationDetail from '../../components/Reservations/UserReservationDetail';

const UserReservationList = (props) => {
  const { match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [userReservations, setUserReservations] = useState();
  const [userReservationTotal, setUserReservationTotal] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState({
    keyword: undefined,
    startAt: undefined,
    endAt: undefined,
    reservationObjId: undefined,
  });
  const [userReservationDetail, setUserReservationDetail] = useState();
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  useEffect(() => {
    getUserReserations();
  }, [pageNum]);

  const getUserReserations = () => {
    setIsLoading(true);

    const startAt =
      trim(search.startAt) !== ''
        ? moment(search.startAt).format('YYYY-MM-DD')
        : '';

    const endAt =
      trim(search.endAt) !== ''
        ? moment(search.endAt).format('YYYY-MM-DD')
        : '';

    reservationAPI
      .getUserReservations(pageNum, LIST_PAGE_COUNT, {
        keyword: search.keyword,
        startAt: startAt,
        endAt: endAt,
        reservationObjId: search.reservationObjId,
      })
      .then(
        (rs) => {
          setUserReservations(rs.userReservationList);
          setUserReservationTotal(rs.totalCount);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleChangeSearch = (name, value) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleSearch = () => {
    if (pageNum === 1) {
      getUserReserations();
    } else {
      setPageNum(1);
    }
  };

  const openDetail = (userReservation) => {
    setUserReservationDetail(userReservation);
    setIsOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setUserReservationDetail(undefined);
    setIsOpenDetail(false);
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              預約記錄
            </Typography>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <DatePicker
            autoOk
            label="開始時間"
            clearable
            inputVariant="outlined"
            value={search.startAt || null}
            onChange={(e) => {
              handleChangeSearch('startAt', e);
            }}
            format="yyyy-MM-dd"
            style={{
              marginRight: '12px',
            }}
          />

          <DatePicker
            autoOk
            label="結束時間"
            clearable
            inputVariant="outlined"
            value={search.endAt || null}
            onChange={(e) => {
              handleChangeSearch('endAt', e);
            }}
            format="yyyy-MM-dd"
            style={{
              marginRight: '12px',
            }}
          />

          <TextField
            label="物件ID"
            type="search"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={search.reservationObjId || ''}
            onChange={(e) => {
              handleChangeSearch('reservationObjId', e.target.value);
            }}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />

          <TextField
            label="手機號碼"
            type="search"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={search.keyword || ''}
            onChange={(e) => {
              handleChangeSearch('keyword', e.target.value);
            }}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={handleSearch}>
            搜尋
          </Button>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && (!userReservations || userReservations.length <= 0) && (
          <TableEmpty message="查無訂位記錄" />
        )}

        {!isLoading && userReservations && userReservations.length > 0 && (
          <UserReservationTable
            userReservations={userReservations}
            total={userReservationTotal}
            openDetail={openDetail}
            pageNum={pageNum}
            setPageNum={setPageNum}
          />
        )}
      </Box>

      <Drawer anchor="right" open={isOpenDetail} onClose={handleCloseDetail}>
        <UserReservationDetail
          userReservation={userReservationDetail}
          closeDrawer={handleCloseDetail}
        />
      </Drawer>
    </div>
  );
};

export default UserReservationList;
