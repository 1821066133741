import React from 'react';
import { Button, TableCell, TableRow, Chip } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import moment from 'moment';

const UserReservationTableRow = (props) => {
  const { userReservation, openDetail } = props;

  const handleOpenDetail = () => {
    openDetail(userReservation);
  };

  const startTimeMoment = moment(userReservation.startTime);
  const endTimeMoment = moment(userReservation.endTime);

  return (
    <TableRow>
      <TableCell component="th">{userReservation.id}</TableCell>
      <TableCell component="th">
        {'ID ' + userReservation.reservationObj.id}
        <br />
        {userReservation.reservationObj.name}
        <Chip
          label={userReservation.reservationObj.reservationType.name}
          color="default"
          size="small"
          style={{ marginLeft: '6px' }}
        />
      </TableCell>
      <TableCell>
        {userReservation.userInfo.cellPhone}
        <br />
        {userReservation.userInfo.realName}
      </TableCell>
      <TableCell>{startTimeMoment.format('YYYY-MM-DD')}</TableCell>
      <TableCell>{startTimeMoment.format('HH:mm')}</TableCell>
      <TableCell>{endTimeMoment.format('HH:mm')}</TableCell>
      <TableCell>{userReservation.reservationNumber}</TableCell>
      <TableCell>{userReservation.totalPrice}</TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          color="default"
          size="small"
          startIcon={<AssignmentIcon />}
          style={{ marginRight: '12px' }}
          onClick={handleOpenDetail}>
          檢視
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default UserReservationTableRow;
