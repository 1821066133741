import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import { GuestCardStatusText, LIST_PAGE_COUNT } from '../../data/common';
import { map } from 'lodash';
import guestCardAPI from '../../middleware/guestCardAPI';
import GuestCardTable from '../../components/GuestCard/GuestCardTable';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Swal from 'sweetalert2';
import GenerateGuestCardModal from '../../components/GuestCard/GenerateGuestCardModal';
import { saveAs } from 'file-saver';
import QrCodeWithLogo from 'qrcode-with-logos';

const GuestCardList = (props) => {
  const { match } = props;
  const [guestCards, setGuestCards] = useState();
  const [guestCardTotal, setGuestCardTotal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({
    status: '',
    code: '',
  });
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    handleSearch();
  }, [page]);

  const handleChangeSearch = (e) => {
    const changeItem = e.target.name;
    const changeValue = e.target.value;

    setSearch({
      ...search,
      [changeItem]: changeValue,
    });
  };

  const handleSearch = () => {
    setIsLoading(true);

    guestCardAPI
      .getGuestCards(search, LIST_PAGE_COUNT, page)
      .then(
        (rs) => {
          setGuestCards(rs.cardList);
          setGuestCardTotal(rs.totalCount);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const generateCards = (generateNumber) => {
    setIsLoading(true);

    return guestCardAPI
      .generateTypeCards(generateNumber)
      .then(
        () => {
          Swal.fire({
            title: '建立完成',
            icon: 'success',
          });

          handleSearch();
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const downloadGuestCardAllQrcode = () => {
    setIsLoading(true);

    return guestCardAPI
      .getGuestCards({ status: undefined, code: undefined }, 10000, 1)
      .then((response) => {
        const zip = require('jszip')();

        return getAllUrlQrcodeImg(response.cardList).then((allQrCodeImg) => {
          for (let urlQrCode of allQrCodeImg) {
            zip.file(urlQrCode.name, urlQrCode.img.split('base64,')[1], {
              base64: true,
            });
          }

          return zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, '共享空間-QRCODE.zip');
            setIsLoading(false);
          });
        });
      });
  };

  const getAllUrlQrcodeImg = async (nodes) => {
    let qrcodes = [];

    for (let node of nodes) {
      const fileName = node.id + '_' + node.code + '.png';
      const qrcodeCanvas = document.createElement('canvas');
      const qrcode = new QrCodeWithLogo({
        canvas: qrcodeCanvas,
        content: node.code,
        width: 100,
      });

      await qrcode.toCanvas().then(() => {
        qrcodes.push({
          name: fileName,
          img: qrcodeCanvas.toDataURL('image/png'),
        });
      });
    }

    return qrcodes;
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              共享空間卡片列表
            </Typography>
          </Grid>

          <Grid item xs style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="default"
              startIcon={<GetAppRoundedIcon />}
              style={{ marginRight: '12px' }}
              onClick={downloadGuestCardAllQrcode}
              disabled={isLoading}>
              下載全部的 QRCode 圖片
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddBoxRoundedIcon />}
              onClick={handleOpenModal}
              disabled={isLoading}>
              建立卡片
            </Button>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading}>
            <InputLabel>狀態</InputLabel>
            <Select
              value={search.status}
              name="status"
              onChange={handleChangeSearch}
              label="狀態">
              <MenuItem value="">不設定</MenuItem>
              {map(GuestCardStatusText, (text, status) => {
                return (
                  <MenuItem value={status} key={status}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            label="CODE"
            type="search"
            name="code"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={search.code}
            onChange={handleChangeSearch}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                if (page !== 1) {
                  setPage(1);
                } else {
                  handleSearch();
                }
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={() => {
              if (page !== 1) {
                setPage(1);
              } else {
                handleSearch();
              }
            }}>
            搜尋
          </Button>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && !guestCards && <TableEmpty message="無共享空間卡片" />}

        {!isLoading && guestCards && (
          <GuestCardTable
            guestCards={guestCards}
            guestCardTotal={guestCardTotal}
            page={page}
            setPage={setPage}
          />
        )}
      </Box>

      <GenerateGuestCardModal
        isOpen={isModalOpen}
        close={handleCloseModal}
        isLoading={isLoading}
        generateCards={generateCards}
      />
    </div>
  );
};

export default GuestCardList;
