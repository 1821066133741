import instance from './API';
import { trim, escape } from 'lodash';
import moment from 'moment';

const reservationAPI = {
  getReservationObjectTypes: () => {
    return instance.get('/ReservationType');
  },
  getReservationObjects: (page, count, searchs) => {
    const getParams = {
      page: page,
      count: count,
    };

    if (trim(searchs.status) !== '') {
      getParams.status = searchs.status;
    }

    if (trim(searchs.type) !== '') {
      getParams.TypeId = searchs.type;
    }

    if (trim(searchs.keyword) !== '') {
      getParams.keyword = searchs.keyword;
    }

    return instance.get('/Reservation', {
      params: getParams,
    });
  },
  getReservationObject: (objectId) => {
    return instance.get(`/Reservation/${objectId}`);
  },
  updateObjectStatus: (objectId, status) => {
    return instance.patch(`/Reservation/${objectId}`, null, {
      params: {
        status: status,
      },
    });
  },
  createObject: (object) => {
    const createParams = new URLSearchParams();

    createParams.append(
      'ReservationObjectTypeId',
      object.reservationObjectTypeId,
    );
    createParams.append('Status', object.status);
    createParams.append('Name', object.name);
    createParams.append('Description', escape(object.description));
    createParams.append('MaxReservationNumber', object.maxReservationNumber);
    createParams.append('MinReservationNumber', object.minReservationNumber);
    createParams.append('Price', object.price);
    createParams.append(
      'StartTimeStr',
      moment(object.startTime).format('HH:mm'),
    );
    createParams.append('EndTimeStr', moment(object.endTime).format('HH:mm'));

    return instance.post(`/Reservation`, createParams);
  },
  updateObject: (objectId, object) => {
    const updateParams = new URLSearchParams();

    updateParams.append(
      'ReservationObjectTypeId',
      object.reservationObjectTypeId,
    );
    updateParams.append('Status', object.status);
    updateParams.append('Name', object.name);
    updateParams.append('Description', escape(object.description));
    updateParams.append('MaxReservationNumber', object.maxReservationNumber);
    updateParams.append('MinReservationNumber', object.minReservationNumber);
    updateParams.append('Price', object.price);
    updateParams.append(
      'StartTimeStr',
      moment(object.startTime).format('HH:mm'),
    );
    updateParams.append('EndTimeStr', moment(object.endTime).format('HH:mm'));

    return instance.put(`/Reservation/${objectId}`, updateParams);
  },
  uploadObjectImg: (objectId, imgFile, isCover) => {
    const uploadParams = new FormData();

    uploadParams.append('File', imgFile);

    return instance.post(`/Reservation/${objectId}/Img`, uploadParams, {
      params: {
        isCover: isCover === true ? true : false,
      },
    });
  },
  deleteObjectImg: (objectId, imgId) => {
    return instance.delete(`/Reservation/${objectId}/Img/${imgId}`);
  },
  setObjectCover: (objectId, imgId) => {
    return instance.patch(`/Reservation/${objectId}/Img/${imgId}`);
  },
  getUserReservations: (page, count, searchs) => {
    let getParams = {
      page: page,
      count: count,
    };

    if (trim(searchs.keyword) !== '') {
      getParams.keyword = searchs.keyword;
    }

    if (trim(searchs.startAt) !== '') {
      getParams.startAt = searchs.startAt;
    }

    if (trim(searchs.endAt) !== '') {
      getParams.endAt = searchs.endAt;
    }

    if (trim(searchs.reservationObjId) !== '') {
      getParams.reservationObjId = searchs.reservationObjId;
    }

    return instance.get('/ReservedList', {
      params: getParams,
    });
  },
};

export default reservationAPI;
