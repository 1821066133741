import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  TextField,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const GenerateGuestCardModal = (props) => {
  const { isOpen, close, isLoading, generateCards } = props;
  const [cardNumber, setCardNumber] = useState();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setCardNumber(undefined);
      close();
    }
  };

  const handleGenerate = () => {
    generateCards(cardNumber).then(() => {
      handleClose();
    });
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth>
      <DialogTitle>建立卡片</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth={true}
              label="卡片數量"
              value={cardNumber || ''}
              onChange={(e) => {
                const intRegex = /^[0-9]*$/;

                if (intRegex.test(e.target.value)) {
                  setCardNumber(e.target.value);
                }
              }}
              disabled={isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerate}
              disabled={isLoading || !cardNumber || cardNumber <= 0}>
              建立卡片
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateGuestCardModal;
