import React, { useEffect, useState } from 'react';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { QrReader } from 'react-qr-reader';
import Alert from '@material-ui/lab/Alert';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { trim } from 'lodash';
import { Button, IconButton, Grid, TextField } from '@material-ui/core';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import { punchCard } from '../../Util/fetchMethods.js';

const BonusQrCodeReader = (props) => {
  const { verifyQrcodeData, cancelReScanQrcode, isLoading } = props;
  const [code, setCode] = useState('');
  const [qrcodeData, setQrcodeData] = useState();
  const [qrcodeError, setQrcodeError] = useState(undefined);

  useEffect(() => {
    if (qrcodeData) {
      verifyQrcodeData(qrcodeData, () => {
        setQrcodeData(undefined);
      });
    }
  }, [qrcodeData]);

  const handleCode = () => {
    verifyQrcodeData(code);
  };

  return (
    <>
      <div className="bonus__title">
        <CropFreeIcon />
        掃描 QRCODE
      </div>

      <div className="bonus__guset-qrcode-reader">
        <QrReader
          onResult={(result, error) => {
            if (!!result && !qrcodeData && !isLoading) {
              //南辦打卡
              if (result?.text.indexOf('signin') !== -1) {
                punchCard(result?.text).then((response) => {
                  alert(response.Message);
                });
              } else {
                setQrcodeData(result?.text);
              }
            }

            if (!!error) {
              setQrcodeError(error);
            }
          }}
          constraints={{
            facingMode: 'environment',
          }}
          style={{ width: '100%' }}
        />
      </div>

      <div className="bonus__guset-code">
        <Grid container direction="row" alignItems="center">
          <Grid item xs={8}>
            <TextField
              label="code"
              fullWidth
              variant="outlined"
              value={code}
              disabled={isLoading}
              helperText="會員紅利 CODE 需要 6 個數字"
              onChange={(e) => {
                const value = trim(e.target.value);
                setCode(value);
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <IconButton
              color="primary"
              disabled={code === '' || isLoading}
              onClick={handleCode}>
              <InputOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>

      {cancelReScanQrcode && (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          startIcon={<RefreshRoundedIcon />}
          onClick={cancelReScanQrcode}>
          取消掃描
        </Button>
      )}

      {trim(qrcodeError) !== '' && (
        <Alert severity="error">{qrcodeError.message}</Alert>
      )}
    </>
  );
};

export default BonusQrCodeReader;
