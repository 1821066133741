import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { find, groupBy, map, sortBy, trim } from 'lodash';
import memberAPI from '../../middleware/memberAPI';
import { alertError } from '../../Util/Util';
import productPackageAPI from '../../middleware/productPackageAPI';
import Swal from 'sweetalert2';
import Barcode from 'react-barcode';
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import RedeemRoundedIcon from '@material-ui/icons/RedeemRounded';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
  },
  coverImg: {
    width: '150px',
    maxHeight: '200px',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  card: {
    margin: '12px 0',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '12px',
  },
  successCard: {
    margin: '12px 0',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '12px',
    color: '#83ad5d',
    fontSize: '2rem',
  },
  name: {
    fontSize: '2rem',
    fontWeight: '600',
  },
  products: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    gap: '6px',
    width: '100%',
  },
  giftIcon: {
    width: '20px',
    verticalAlign: 'bottom',
    color: '#239d77',
    marginLeft: '3px',
  },
}));

const ProductPackageBuy = (props) => {
  const classes = useStyles();
  const { scanData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [packageId, setPackageId] = useState();
  const [user, setUser] = useState();
  const [productPackage, setProductPackage] = useState();
  const [pageStep, setPageStep] = useState('check');
  const [cover, setCover] = useState();
  const [groupProducts, setGroupProducts] = useState();

  useEffect(() => {
    if (trim(scanData) !== '') {
      const [userId, packageId] = scanData.substring(12).split('_');
      setUserId(userId);
      setPackageId(packageId);
    } else {
      setUserId(undefined);
      setPackageId(undefined);
    }
  }, [scanData]);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);

      memberAPI
        .getMember(userId)
        .then(
          (rs) => {
            setUser(rs);
          },
          (error) => {
            alertError(error);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [userId]);

  useEffect(() => {
    if (packageId) {
      setIsLoading(true);

      productPackageAPI
        .getProductPackage(packageId)
        .then(
          (rs) => {
            if (!!rs) {
              setProductPackage(rs);
            } else {
              Swal.fire({
                title: '找不到套券資料',
                icon: 'error',
              });
            }
          },
          (error) => {
            alertError(error);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [packageId]);

  useEffect(() => {
    if (productPackage) {
      const findCover = find(
        productPackage.packageProductImageList,
        (image) => image.isCover === true,
      );

      setCover(findCover ? findCover : undefined);

      const newGroupProducts = groupBy(
        sortBy(productPackage.packageProductList),
        (product) => {
          return `${product.isGift ? '1' : '0'}_${product.bonusProductInfo.id}`;
        },
      );

      setGroupProducts(newGroupProducts);
    }
  }, [productPackage]);

  const handleSendProductPackage = () => {
    if (trim(packageId) === '' || trim(userId) === '') {
      Swal.fire({
        title: '無法發送套券',
        icon: 'error',
        text: '缺少套券 ID 或 使用者 ID',
      });

      return false;
    }

    Swal.fire({
      title: '再次確認發送套券',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '確認',
    }).then((rs) => {
      if (rs.value) {
        setIsLoading(true);

        productPackageAPI
          .sendProductPackageToUser(packageId, userId)
          .then(
            () => {
              setPageStep('done');
            },
            (error) => {
              alertError(error);
            },
          )
          .then(() => {
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <div className={classes.root}>
      {pageStep === 'done' && (
        <div className={classes.successCard}>
          <ThumbUpRoundedIcon />
          發送完成
        </div>
      )}

      {pageStep === 'check' && (
        <>
          {productPackage && (
            <div className={classes.card}>
              {cover && (
                <img src={cover.imageUrl} className={classes.coverImg} />
              )}

              <div className={classes.name}>{productPackage.name}</div>

              <Barcode
                height={30}
                fontSize={16}
                value={productPackage.barcode}
                width={1}
              />

              {groupProducts && (
                <Accordion style={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                    <Typography>產品內容</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.products}>
                      {map(groupProducts, (products, productIndex) => {
                        const product = products[0];

                        return (
                          <div key={productIndex}>
                            {product.bonusProductInfo.name} X {products.length}
                            {product.isGift && (
                              <RedeemRoundedIcon className={classes.giftIcon} />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          )}

          <Divider variant="fullWidth" />

          {user && (
            <div className={classes.card}>
              <div className={classes.name}>{user.realName}</div>
              <div>
                {trim(user.cellPhone) !== '' ? user.cellPhone : user.email}
              </div>
            </div>
          )}

          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            startIcon={<HowToRegRoundedIcon />}
            disabled={isLoading}
            onClick={handleSendProductPackage}>
            發送套券
          </Button>
        </>
      )}
    </div>
  );
};

export default ProductPackageBuy;
