import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
  Avatar,
  IconButton,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import { map, forEach } from 'lodash';
import {
  BonusProductTypeText,
  UserProductPackageOrderStatus,
  UserProductPackageOrderStatusText,
  UserBonusProductStatus,
  UserBonusProductStatusText,
} from '../../data/common';
import { findCover } from '../../Util/Util';
import CropDinRoundedIcon from '@material-ui/icons/CropDinRounded';
import ConfirmationNumberRoundedIcon from '@material-ui/icons/ConfirmationNumberRounded';
import RedeemRoundedIcon from '@material-ui/icons/RedeemRounded';

const USER_BONUS_PRODUCT_STATUS_COLORS = {
  [UserBonusProductStatus.UN_USE]: '#ff8383',
  [UserBonusProductStatus.USED]: '#212121',
  [UserBonusProductStatus.CANCEL]: '#b99797',
  [UserBonusProductStatus.GIFTED]: '#857b47',
  [UserBonusProductStatus.EXPIRED]: '#b99797',
};

const UserProductPackageOrderDetail = (props) => {
  const { userProductPackageOrder, closeDrawer } = props;
  const [cover, setCover] = useState();
  const [countProducts, setCountProducts] = useState();

  useEffect(() => {
    if (userProductPackageOrder) {
      setCover(
        findCover(
          userProductPackageOrder.userProductPackageDetail.packageInfo
            .packageProductImageList,
        ),
      );

      let newCountProducts = {};

      forEach(
        userProductPackageOrder.userProductPackageDetail.packageDetail,
        (userProduct) => {
          const groupKey = userProduct.isGift
            ? 'gift_'
            : '' + userProduct.bonusProductId;

          if (!newCountProducts[groupKey]) {
            newCountProducts[groupKey] = {
              name: userProduct.productInfo.name,
              used: 0,
              given: 0,
              unUse: 0,
              cancel: 0,
              total: 0,
              isGift: userProduct.isGift,
              userProducts: [],
            };
          }

          if (userProduct.status === UserBonusProductStatus.UN_USE) {
            newCountProducts[groupKey].unUse =
              newCountProducts[groupKey].unUse + 1;
          }

          if (userProduct.status === UserBonusProductStatus.USED) {
            newCountProducts[groupKey].used =
              newCountProducts[groupKey].used + 1;
          }

          if (userProduct.status === UserBonusProductStatus.GIFTED) {
            newCountProducts[groupKey].given =
              newCountProducts[groupKey].given + 1;
          }

          if (userProduct.status === UserBonusProductStatus.CANCEL) {
            newCountProducts[groupKey].cancel =
              newCountProducts[groupKey].cancel + 1;
          }

          newCountProducts[groupKey].total =
            newCountProducts[groupKey].total + 1;
          newCountProducts[groupKey].userProducts.push(userProduct);
        },
      );

      setCountProducts(newCountProducts);
    }
  }, [userProductPackageOrder]);

  const handleClose = () => {
    closeDrawer();
  };

  return (
    <div className="bonus__view">
      <div className="bonus__close-view">
        關閉
        <IconButton onClick={handleClose}>
          <CancelIcon />
        </IconButton>
      </div>

      {userProductPackageOrder && (
        <Grid
          container
          direction="column"
          justifycontent="flex-start"
          alignItems="stretch"
          spacing={3}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  cover ? (
                    <Avatar src={cover.imageUrl} />
                  ) : (
                    <Avatar>
                      <CropDinRoundedIcon />
                    </Avatar>
                  )
                }
                title={
                  <Typography variant="h3">
                    {
                      userProductPackageOrder.userProductPackageDetail
                        .packageInfo.name
                    }
                  </Typography>
                }
                subheader={
                  BonusProductTypeText[
                    userProductPackageOrder.userProductPackageDetail.packageInfo
                      .type
                  ]
                }
              />

              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifycontent="space-around"
                  alignItems="stretch"
                  spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">狀態</Typography>
                    <Typography variant="h6">
                      {
                        UserProductPackageOrderStatusText[
                          userProductPackageOrder.status
                        ]
                      }
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">銷售人員</Typography>
                    <Typography variant="h6">
                      {userProductPackageOrder.staffInfo.realName}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">購買人</Typography>
                    <Typography variant="h6">
                      {userProductPackageOrder.userInfo.realName}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">購買時間</Typography>
                    <Typography variant="subtitle2">
                      {moment(userProductPackageOrder.createdDate).format(
                        'YYYY-MM-DD HH:mm:ss',
                      )}
                    </Typography>
                  </Grid>

                  {userProductPackageOrder.status ===
                    UserProductPackageOrderStatus.CANCEL && (
                    <>
                      <Grid item xs={6}>
                        <Typography color="textSecondary">取消人員</Typography>
                        <Typography variant="h6">
                          {userProductPackageOrder.modifiedUserInfo.realName}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography color="textSecondary">取消時間</Typography>
                        <Typography variant="subtitle2">
                          {moment(userProductPackageOrder.modifiedDate).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Typography color="textSecondary">訂單編號</Typography>
                    <Typography variant="body1">
                      {userProductPackageOrder.orderNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {map(countProducts, (countProduct, countProductIndex) => {
            return (
              <Grid item xs={12} key={countProductIndex}>
                <Card variant="outlined">
                  <CardHeader
                    avatar={
                      <Avatar>
                        {countProduct.isGift ? (
                          <RedeemRoundedIcon />
                        ) : (
                          <ConfirmationNumberRoundedIcon />
                        )}
                      </Avatar>
                    }
                    title={
                      <Typography variant="h5">{countProduct.name}</Typography>
                    }
                    subheader={
                      BonusProductTypeText[
                        countProduct.userProducts[0].productInfo.type
                      ] + (countProduct.isGift ? ' - 贈品' : '')
                    }
                  />

                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifycontent="space-around"
                      alignItems="stretch"
                      spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          color="textSecondary"
                          style={{ textAlign: 'center' }}>
                          總計
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ textAlign: 'center' }}>
                          {countProduct.total}
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography
                          color="textSecondary"
                          style={{ textAlign: 'center' }}>
                          未使用
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ textAlign: 'center' }}>
                          {countProduct.unUse}
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography
                          color="textSecondary"
                          style={{ textAlign: 'center' }}>
                          已使用
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ textAlign: 'center' }}>
                          {countProduct.used}
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography
                          color="textSecondary"
                          style={{ textAlign: 'center' }}>
                          已贈送
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ textAlign: 'center' }}>
                          {countProduct.given}
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography
                          color="textSecondary"
                          style={{ textAlign: 'center' }}>
                          取消
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ textAlign: 'center' }}>
                          {countProduct.cancel}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {map(countProduct.userProducts, (userProduct) => {
                          return (
                            <div
                              key={userProduct.id}
                              style={{
                                margin: '6px 0',
                                border: '1px solid #eee',
                                padding: '6px',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              <div>
                                <div
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: '600',
                                  }}>
                                  {userProduct.productInfo.name}
                                </div>

                                <div
                                  style={{
                                    margin: '3px 0',
                                    fontSize: '0.8rem',
                                    fontWeight: '500',
                                    color: '#bdaaaa',
                                  }}>
                                  領券時間{' '}
                                  {moment(userProduct.createdDate).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                  )}
                                </div>

                                {userProduct.isGift && (
                                  <div
                                    style={{
                                      margin: '3px 0',
                                      fontSize: '0.8rem',
                                      fontWeight: '500',
                                      color: '#bdaaaa',
                                    }}>
                                    到期時間{' '}
                                    {moment(userProduct.expiryDate).format(
                                      'YYYY-MM-DD HH:mm:ss',
                                    )}
                                  </div>
                                )}

                                {userProduct.status ===
                                  UserBonusProductStatus.USED && (
                                  <div
                                    style={{
                                      margin: '3px 0',
                                      fontSize: '0.8rem',
                                      fontWeight: '500',
                                      color: '#1a1717',
                                    }}>
                                    使用時間{' '}
                                    {moment(userProduct.useTime).format(
                                      'YYYY-MM-DD HH:mm:ss',
                                    )}
                                  </div>
                                )}

                                {userProduct.status ===
                                  UserBonusProductStatus.GIFTED && (
                                  <div
                                    style={{
                                      margin: '3px 0',
                                      fontSize: '0.8rem',
                                      fontWeight: '500',
                                      color: '#1a1717',
                                    }}>
                                    贈送時間{' '}
                                    {moment(userProduct.modifiedDate).format(
                                      'YYYY-MM-DD HH:mm:ss',
                                    )}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{
                                  fontSize: '1.6rem',
                                  fontWeight: '600',
                                  display: 'flex',
                                  alignItems: 'center',
                                  color:
                                    USER_BONUS_PRODUCT_STATUS_COLORS[
                                      userProduct.status
                                    ],
                                }}>
                                {UserBonusProductStatusText[userProduct.status]}
                              </div>
                            </div>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default UserProductPackageOrderDetail;
