import instance from './API';
import { trim } from 'lodash';

const productPackageAPI = {
  getProductPackage: (id) => {
    return instance.get(`/ProductPackage/${id}`);
  },
  sendProductPackageToUser: (packageId, userId) => {
    return instance.post(`/AddPackageToUser/${packageId}`, null, {
      params: {
        customerId: userId,
      },
    });
  },
  getUserProductPackage: (orderNumber, userId) => {
    return instance.get(`/UserProductPackage`, {
      params: {
        orderNumber: orderNumber,
        customerId: userId,
      },
    });
  },
  cancelUserProductPackage: (orderNumber, userId) => {
    return instance.delete('/CancelProductPackage', {
      params: {
        orderNumber: orderNumber,
        customerId: userId,
      },
    });
  },
  getUserProductPackages: (page, count, status) => {
    return instance.get('/UserProductPackageList', {
      params: {
        page: page,
        count: count,
        status: status,
      },
    });
  },
};

export default productPackageAPI;
