import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Page404 from '../../components/Page404';
import ReservationObjectList from './ReservationObjectList';
import ReservationObject from './ReservationObject';
import UserReservationList from './UserReservationList';

const ReservationRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`/Reservations/ReservationObjectList`}
        component={ReservationObjectList}
      />
      <Route
        exact
        path={`/Reservations/ReservationObject/:objectId?`}
        component={ReservationObject}
      />
      <Route
        exact
        path={`/Reservations/ReservationList`}
        component={UserReservationList}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default ReservationRoute;
