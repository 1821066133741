import React, { useEffect, useState } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { find, map, parseInt, trim, unescape } from 'lodash';
import Swal from 'sweetalert2';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import KeyboardReturnOutlinedIcon from '@material-ui/icons/KeyboardReturnOutlined';
import { alertError } from '../../Util/Util';
import { ReservationObjectStatus } from '../../data/common';
import Editor from '../../components/Editor';
import { AWS_BONUS_PRODUCT_POSITION } from '../../data/aws';
import { TimePicker } from '@material-ui/pickers';
import { useDispatch } from 'react-redux';
import reservationAPI from '../../middleware/reservationAPI';

const ReservationObject = (props) => {
  const { match } = props;
  const { objectId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(() => {
    return trim(objectId) === '';
  });
  const [form, setForm] = useState({
    id: 0,
    reservationObjectTypeId: '',
    status: 0,
    name: '',
    description: '',
    maxReservationNumber: 0,
    minReservationNumber: 0,
    price: '',
    startTime: new Date().setHours(11, 0),
    endTime: new Date().setHours(20, 30),
  });
  const [reservationObjectTypes, setReservationObjectTypes] = useState();

  useEffect(() => {
    const checkIsCreateRs = trim(objectId) === '';

    setIsCreate(checkIsCreateRs);

    const init = async () => {
      setIsLoading(true);

      await getTypes();

      if (!checkIsCreateRs) {
        await getObject();
      }

      setIsLoading(false);
    };

    init();
  }, [objectId]);

  const getTypes = () => {
    return reservationAPI.getReservationObjectTypes().then(
      (rs) => {
        setReservationObjectTypes(rs);
      },
      (error) => {
        alertError(error);
      },
    );
  };

  const getObject = () => {
    return reservationAPI.getReservationObject(objectId).then(
      (rs) => {
        setForm({
          id: rs.id,
          reservationObjectTypeId: rs.reservationObjectTypeId,
          status: rs.status,
          name: rs.name,
          description: unescape(rs.description),
          maxReservationNumber: rs.maxReservationNumber,
          minReservationNumber: rs.minReservationNumber,
          price: rs.price,
          startTime: new Date().setHours(
            rs.startTime.substring(0, 2),
            rs.startTime.substring(3, 5),
          ),
          endTime: new Date().setHours(
            rs.endTime.substring(0, 2),
            rs.endTime.substring(3, 5),
          ),
        });
      },
      (error) => {
        alertError(error);
      },
    );
  };

  const handleChangeForm = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (
      !find(
        reservationObjectTypes,
        (reservationObjectType) =>
          reservationObjectType.id === form.reservationObjectTypeId,
      )
    ) {
      Swal.fire({
        title: '請選擇類型',
        icon: 'info',
      });

      return false;
    }

    if (trim(form.name) === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'info',
      });

      return false;
    }

    if (parseInt(form.maxReservationNumber) <= 0) {
      Swal.fire({
        title: '請輸入可訂位人數',
        icon: 'info',
      });

      return false;
    }

    if (parseInt(form.minReservationNumber) <= 0) {
      Swal.fire({
        title: '請輸入最少訂位人數',
        icon: 'info',
      });

      return false;
    }

    if (
      parseInt(form.maxReservationNumber) < parseInt(form.minReservationNumber)
    ) {
      Swal.fire({
        title: '最少訂位人數不能大於可訂位人數',
        icon: 'info',
      });

      return false;
    }

    if (parseInt(form.price) <= 0 || trim(form.price) === '') {
      Swal.fire({
        title: '請設定價格',
        icon: 'info',
      });

      return false;
    }

    setIsLoading(true);

    const promise = isCreate
      ? reservationAPI.createObject(form)
      : reservationAPI.updateObject(objectId, form);

    promise
      .then(
        (rs) => {
          Swal.fire({
            title: '儲存完成',
            icon: 'success',
          });
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {isCreate ? '建立預約物件' : '修改預約物件'}
        </Typography>

        <Divider light />

        <Box p={3}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth={true}
                label="名稱"
                value={form.name || ''}
                onChange={(e) => {
                  handleChangeForm('name', e.target.value);
                }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth required disabled={isLoading}>
                <InputLabel>類型</InputLabel>
                <Select
                  value={form.reservationObjectTypeId}
                  onChange={(e) => {
                    handleChangeForm(
                      'reservationObjectTypeId',
                      parseInt(e.target.value, 10),
                    );
                  }}
                  label="類型">
                  {reservationObjectTypes &&
                    map(reservationObjectTypes, (reservationObjectType) => (
                      <MenuItem
                        value={reservationObjectType.id}
                        key={reservationObjectType.id}>
                        {reservationObjectType.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth={true}
                label="價格"
                required
                value={form.price || ''}
                type="number"
                onChange={(e) => {
                  handleChangeForm('price', e.target.value);
                }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth disabled={isLoading}>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      value={ReservationObjectStatus.ONLINE}
                      color="primary"
                      checked={form.status === ReservationObjectStatus.ONLINE}
                      onChange={(e) => {
                        handleChangeForm(
                          'status',
                          e.target.checked
                            ? ReservationObjectStatus.ONLINE
                            : ReservationObjectStatus.OFFLINE,
                        );
                      }}
                    />
                  }
                  label="上架"
                  labelPlacement="bottom"
                />
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth={true}
                label="可訂位人數"
                value={form.maxReservationNumber || ''}
                type="number"
                onChange={(e) => {
                  handleChangeForm('maxReservationNumber', e.target.value);
                }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth={true}
                label="最少訂位人數"
                value={form.minReservationNumber || ''}
                type="number"
                onChange={(e) => {
                  handleChangeForm('minReservationNumber', e.target.value);
                }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={4}>
              <TimePicker
                label="開放時間"
                clearable
                fullWidth
                value={form.startTime}
                onChange={(time) => {
                  handleChangeForm('startTime', time);
                }}
                format="HH:mm"
                ampm={false}
              />
            </Grid>

            <Grid item xs={4}>
              <TimePicker
                label="結束時間"
                clearable
                fullWidth
                value={form.endTime}
                onChange={(time) => {
                  handleChangeForm('endTime', time);
                }}
                format="HH:mm"
                ampm={false}
              />
            </Grid>

            <Grid item xs={12}>
              <Editor
                content={form.description}
                setContent={(newContent) => {
                  handleChangeForm('description', newContent);
                }}
                imageS3Position={{
                  bucket: AWS_BONUS_PRODUCT_POSITION.description.bucket,
                  dir: AWS_BONUS_PRODUCT_POSITION.description.dir,
                  acl: AWS_BONUS_PRODUCT_POSITION.description.acl,
                }}
                editorProps={{
                  height: '350',
                  placeholder: '物件說明',
                }}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider light />

        <Box p={1} mt={2} mb={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginRight: '24px' }}
            startIcon={<SaveOutlinedIcon />}
            disabled={isLoading}
            onClick={handleSubmit}>
            {isCreate ? '建立' : '修改'}
          </Button>
          <Button
            variant="contained"
            color="default"
            size="large"
            startIcon={<KeyboardReturnOutlinedIcon />}
            disabled={isLoading}
            onClick={() => {
              history.push('/Reservations/ReservationObjectList');
            }}>
            回列表
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default ReservationObject;
