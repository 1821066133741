import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
  Avatar,
  IconButton,
} from '@material-ui/core';
import { formatNumberComma, seconds2time } from '../../Util/Util';
import CancelIcon from '@material-ui/icons/Cancel';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';

const GuestCardHistoryDetail = (props) => {
  const { history, closeDrawer } = props;

  const handleClose = () => {
    closeDrawer();
  };

  return (
    <div className="bonus__view">
      <div className="bonus__close-view">
        關閉
        <IconButton onClick={handleClose}>
          <CancelIcon />
        </IconButton>
      </div>

      {history && (
        <Grid
          container
          direction="column"
          justifycontent="flex-start"
          alignItems="stretch"
          spacing={3}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <>
                    {history.cardType ? (
                      <Avatar src={history.cardType.frontImgUrl}></Avatar>
                    ) : (
                      <Avatar>
                        <ErrorOutlineRoundedIcon />
                      </Avatar>
                    )}
                  </>
                }
                title={
                  <Typography variant="h3">
                    {history.cardType && history.cardType.name}
                  </Typography>
                }
                subheader={history.cardType && history.cardType.description}
              />

              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifycontent="space-around"
                  alignItems="stretch"
                  spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">狀態</Typography>
                    <Typography variant="h6">
                      {history.historyStatus === 'bill' ? '已結帳' : '使用中'}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">金額</Typography>
                    <Typography variant="h6">
                      {history.historyStatus === 'bill' && (
                        <span>
                          $ {formatNumberComma(history.expenseAmount)}
                        </span>
                      )}
                    </Typography>
                  </Grid>

                  {history.useTarget === 'card' && (
                    <>
                      <Grid item xs={6}>
                        <Typography color="textSecondary">CardId</Typography>
                        <Typography variant="h6">
                          {history.cardInfo.id}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography color="textSecondary">CardCode</Typography>
                        <Typography variant="h6">
                          {history.cardInfo.code}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {history.useTarget === 'user' && (
                    <>
                      <Grid item xs={6}>
                        <Typography color="textSecondary">UserId</Typography>
                        <Typography variant="h6">
                          {history.userInfo.id}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography color="textSecondary">姓名</Typography>
                        <Typography variant="h6">
                          {history.userInfo.realName}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={6}>
                    <Typography color="textSecondary">使用時間</Typography>
                    <Typography variant="h6">
                      {seconds2time(history.useTimeSec, true)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">建立者ID</Typography>
                    <Typography variant="h6">
                      {history.createdUserId}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary">進場時間</Typography>
                    <Typography variant="h6">{history.signInTime}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary">出場時間</Typography>
                    <Typography variant="h6">{history.signOutTime}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar>
                    <MonetizationOnRoundedIcon />
                  </Avatar>
                }
                title={<Typography variant="h3">使用算法</Typography>}
                subheader={`當時結算記錄`}
              />

              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifycontent="space-around"
                  alignItems="stretch"
                  spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">初始分鐘</Typography>
                    <Typography variant="h6">
                      {history.initialMinutes}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">初始價格</Typography>
                    <Typography variant="h6">{history.initialPrice}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">時段分鐘</Typography>
                    <Typography variant="h6">
                      {history.periodMinutes}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">時段價格</Typography>
                    <Typography variant="h6">{history.periodPrice}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">優惠分鐘</Typography>
                    <Typography variant="h6">
                      {history.discountMinutes}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">最高收費分鐘</Typography>
                    <Typography variant="h6">
                      {history.maxChargeMinutes}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default GuestCardHistoryDetail;
