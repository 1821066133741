import React from 'react';
import { TableCell, TableRow, Chip, Button } from '@material-ui/core';
import moment from 'moment';
import { trim } from 'lodash';
import AlarmOffRoundedIcon from '@material-ui/icons/AlarmOffRounded';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import QrCodeWithLogo from 'qrcode-with-logos';

const GuestCardTableRow = (props) => {
  const { guestCard } = props;

  const isUse =
    guestCard.cardHistory &&
    trim(guestCard.cardHistory.signInTime) !== '' &&
    trim(guestCard.cardHistory.signOutTime) === '';

  const handleDownloadQRCode = async () => {
    const fileName = trim(guestCard.id) + '-' + guestCard.code + '.png';
    const qrcodeCanvas = document.createElement('canvas');
    const qrcode = new QrCodeWithLogo({
      canvas: qrcodeCanvas,
      content: guestCard.code,
      width: 100,
      nodeQrCodeOptions: {
        margin: 1,
      },
    });

    qrcode.downloadImage(fileName);
  };

  return (
    <TableRow>
      <TableCell component="th">{guestCard.id}</TableCell>

      <TableCell component="th">{guestCard.code}</TableCell>

      <TableCell>
        <Chip
          label={isUse ? '使用中' : '未使用'}
          icon={isUse ? <TimerRoundedIcon /> : <AlarmOffRoundedIcon />}
          color={isUse ? 'primary' : 'default'}
        />
      </TableCell>

      <TableCell>
        {isUse &&
          moment(guestCard.cardHistory.signInTime).format(
            'YYYY-MM-DD HH:mm:ss',
          )}
      </TableCell>

      <TableCell align="right">
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<GetAppRoundedIcon />}
          style={{ marginRight: '12px' }}
          onClick={handleDownloadQRCode}>
          下載 QRCode
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default GuestCardTableRow;
