import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import {
  LIST_PAGE_COUNT,
  UserProductPackageOrderStatusText,
} from '../../data/common';
import productPackageAPI from '../../middleware/productPackageAPI';
import { map } from 'lodash';
import UserProductPackageOrderTable from '../../components/ProductPackages/UserProductPackageOrderTable';
import UserProductPackageOrderDetail from '../../components/ProductPackages/UserProductPackageOrderDetail';

const UserProductPackageOrderList = (props) => {
  const { match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [userProductPackageOrders, setUserProductPackageOrders] = useState();
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({
    status: -1,
  });
  const [detail, setDetail] = useState();
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  useEffect(() => {
    getUserProductPackageOrders();
  }, [page]);

  const getUserProductPackageOrders = () => {
    setIsLoading(true);

    productPackageAPI
      .getUserProductPackages(page, LIST_PAGE_COUNT, search.status)
      .then(
        (rs) => {
          setTotalCount(rs.totalCount);
          setUserProductPackageOrders(rs.userProductPackageList);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleChangeSearch = (name, value) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleSearch = () => {
    if (page === 1) {
      getUserProductPackageOrders();
    } else {
      setPage(1);
    }
  };

  const openDetail = (userProductPackageOrder) => {
    setDetail(userProductPackageOrder);
    setIsOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setDetail(undefined);
    setIsOpenDetail(false);
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              銷售套券記錄
            </Typography>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading}>
            <InputLabel>狀態</InputLabel>
            <Select
              value={search.status}
              name="status"
              onChange={(e) => {
                handleChangeSearch('status', e.target.value);
              }}
              label="狀態">
              <MenuItem value="-1">不設定</MenuItem>
              {map(UserProductPackageOrderStatusText, (text, status) => {
                return (
                  <MenuItem value={status} key={status}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={handleSearch}>
            搜尋
          </Button>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading &&
          (!userProductPackageOrders ||
            userProductPackageOrders.length <= 0) && (
            <TableEmpty message="查無銷售套券記錄" />
          )}

        {!isLoading &&
          userProductPackageOrders &&
          userProductPackageOrders.length > 0 && (
            <UserProductPackageOrderTable
              userProductPackageOrders={userProductPackageOrders}
              totalCount={totalCount}
              openDetail={openDetail}
              page={page}
              setPage={setPage}
            />
          )}
      </Box>

      <Drawer anchor="right" open={isOpenDetail} onClose={handleCloseDetail}>
        <UserProductPackageOrderDetail
          userProductPackageOrder={detail}
          closeDrawer={handleCloseDetail}
        />
      </Drawer>
    </div>
  );
};

export default UserProductPackageOrderList;
