import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import { map, find } from 'lodash';
import reservationAPI from '../../middleware/reservationAPI';
import ReservationObjectTable from '../../components/Reservations/ReservationObjectTable';
import { useHistory } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import {
  LIST_PAGE_COUNT,
  ReservationObjectStatusText,
} from '../../data/common';
import ReservationObjectImgSettingModal from '../../components/Reservations/ReservationObjectImgSettingModal';

const ReservationObjectList = (props) => {
  const { match } = props;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [reservationObjectTypes, setReservationObjectTypes] = useState();
  const [reservationObjects, setReservationObjects] = useState();
  const [reservationObjectTotal, setReservationObjectTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({
    status: '',
    type: '',
    keyword: '',
  });
  const [isOpenImgSettingModal, setIsOpenImgSettingModal] = useState(false);
  const [imgSettingObject, setImgSettingObject] = useState();

  useEffect(() => {
    if (!reservationObjectTypes) {
      reservationAPI.getReservationObjectTypes().then(
        (rs) => {
          setReservationObjectTypes(rs);
        },
        (error) => {
          alertError(error);
        },
      );
    }
  }, []);

  useEffect(() => {
    handleSearch();
  }, [page]);

  const handleChangeSearch = (e) => {
    const changeItem = e.target.name;
    const changeValue = e.target.value;

    setSearch({
      ...search,
      [changeItem]: changeValue,
    });
  };

  const handleSearch = (imgSettingObjectId = undefined) => {
    setIsLoading(true);

    reservationAPI
      .getReservationObjects(page, LIST_PAGE_COUNT, search)
      .then(
        (rs) => {
          setReservationObjects(rs.reservationObjList);
          setReservationObjectTotal(rs.totalCount);

          // 圖設定上傳完成後更新列表並將新資料帶入 imgSettingObject
          if (imgSettingObjectId) {
            setImgSettingObject(
              find(
                rs.reservationObjList,
                (reservationObj) => reservationObj.id === imgSettingObjectId,
              ),
            );
          }
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const toggleStatus = (objectId, status) => {
    setIsLoading(true);

    reservationAPI.updateObjectStatus(objectId, status).then(
      () => {
        handleSearch();
      },
      (error) => {
        alertError(error);
        setIsLoading(false);
      },
    );
  };

  const toEditReservationObject = (objectId) => {
    history.push(`/Reservations/ReservationObject/${objectId}`);
  };

  const handleCreate = () => {
    history.push('/Reservations/ReservationObject');
  };

  const openImgSettingModal = (object) => {
    setImgSettingObject(object);
    setIsOpenImgSettingModal(true);
  };

  const closeImgSettingModal = () => {
    setImgSettingObject(undefined);
    setIsOpenImgSettingModal(false);
  };

  const uploadImg = (objectId, uploadFile, isCover) => {
    setIsLoading(true);

    return reservationAPI
      .uploadObjectImg(objectId, uploadFile, isCover)
      .then(
        (rs) => {
          refreshImgSettingObject(objectId);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const deleteImg = (objectId, imgId) => {
    setIsLoading(true);

    return reservationAPI
      .deleteObjectImg(objectId, imgId)
      .then(
        () => {
          refreshImgSettingObject(objectId);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const setCover = (objectId, imgId) => {
    setIsLoading(true);

    return reservationAPI
      .setObjectCover(objectId, imgId)
      .then(
        () => {
          refreshImgSettingObject(objectId);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const refreshImgSettingObject = (objectId) => {
    handleSearch(objectId);
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              預訂物件列表
            </Typography>
          </Grid>

          <Grid item xs style={{ marignLeft: 'auto' }}>
            <div style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                disabled={isLoading}
                onClick={handleCreate}>
                建立物件
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading}>
            <InputLabel>狀態</InputLabel>
            <Select
              value={search.status}
              name="status"
              onChange={handleChangeSearch}
              label="狀態">
              <MenuItem value="">不設定</MenuItem>
              {map(ReservationObjectStatusText, (text, status) => {
                return (
                  <MenuItem value={status} key={status}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading}>
            <InputLabel>類型</InputLabel>
            <Select
              value={search.type}
              name="type"
              onChange={handleChangeSearch}
              label="類型">
              <MenuItem value="">不設定</MenuItem>
              {reservationObjectTypes &&
                map(reservationObjectTypes, (reservationObjectType) => {
                  return (
                    <MenuItem
                      value={reservationObjectType.id}
                      key={reservationObjectType.id}>
                      {reservationObjectType.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <TextField
            label="名稱"
            type="search"
            name="keyword"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={search.keyword}
            onChange={handleChangeSearch}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                if (page !== 1) {
                  setPage(1);
                } else {
                  handleSearch();
                }
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={() => {
              if (page !== 1) {
                setPage(1);
              } else {
                handleSearch();
              }
            }}>
            搜尋
          </Button>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && !reservationObjects && (
          <TableEmpty message="無預約物件" />
        )}

        {!isLoading && reservationObjects && (
          <ReservationObjectTable
            reservationObjects={reservationObjects}
            page={page}
            setPage={setPage}
            total={reservationObjectTotal}
            toggleStatus={toggleStatus}
            toEditReservationObject={toEditReservationObject}
            openImgSettingModal={openImgSettingModal}
          />
        )}
      </Box>

      <ReservationObjectImgSettingModal
        isOpen={isOpenImgSettingModal}
        close={closeImgSettingModal}
        reservationObject={imgSettingObject}
        uploadImg={uploadImg}
        deleteImg={deleteImg}
        setCover={setCover}
        refreshObject={refreshImgSettingObject}
        isLoading={isLoading}
        setIsLoadig={setIsLoading}
      />
    </div>
  );
};

export default ReservationObjectList;
