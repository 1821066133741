import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Page404 from '../../components/Page404';
import UserProductPackageOrderList from './UserProductPackageOrderList';

const ProductPackageRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`/ProductPackages/UserProductPackageOrderList`}
        component={UserProductPackageOrderList}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default ProductPackageRoute;
