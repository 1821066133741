import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { map } from 'lodash';
import TablePaginationActions from '../TablePaginationActions';
import { LIST_PAGE_COUNT } from '../../data/common';
import ReservationObjectTableRow from './ReservationObjectTableRow';

const ReservationObjectTable = (props) => {
  const {
    reservationObjects,
    total,
    page,
    setPage,
    toggleStatus,
    toEditReservationObject,
    openImgSettingModal,
  } = props;

  return (
    <TableContainer component={Box}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>封面</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>名稱</TableCell>
            <TableCell>類型</TableCell>
            <TableCell>開放時間</TableCell>
            <TableCell>價格</TableCell>
            <TableCell>可訂位人數</TableCell>
            <TableCell>最低訂位人數</TableCell>
            <TableCell>狀態</TableCell>
            <TableCell align="right">功能</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {map(reservationObjects, (reservationObject) => (
            <ReservationObjectTableRow
              key={reservationObject.id}
              reservationObject={reservationObject}
              toggleStatus={toggleStatus}
              toEditReservationObject={toEditReservationObject}
              openImgSettingModal={openImgSettingModal}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[LIST_PAGE_COUNT]}
              colSpan={10}
              count={total}
              rowsPerPage={LIST_PAGE_COUNT}
              page={page - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(event, currentPage) => {
                setPage(currentPage + 1);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ReservationObjectTable;
