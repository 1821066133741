import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
  Avatar,
  IconButton,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CropOriginalRoundedIcon from '@material-ui/icons/CropOriginalRounded';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import moment from 'moment';
import { find, unescape } from 'lodash';
import { ReservationObjectStatusText } from '../../data/common';

const UserReservationDetail = (props) => {
  const { userReservation, closeDrawer } = props;
  const [reservationObjectAvatar, setReservationObjectAvatar] = useState();

  useEffect(() => {
    let newAvatar;

    if (
      userReservation &&
      userReservation.reservationObj.reservationImageList &&
      userReservation.reservationObj.reservationImageList.length > 0
    ) {
      newAvatar = find(
        userReservation.reservationObj.reservationImageList,
        (reservationImage) => reservationImage.isCover === true,
      );
    }

    setReservationObjectAvatar(
      newAvatar ? (
        <img
          src={newAvatar.imageUrl}
          style={{
            maxWidth: '53px',
            minHeight: '75px',
            objectFit: 'cover',
            borderRadius: '12px',
          }}
        />
      ) : (
        <CropOriginalRoundedIcon />
      ),
    );
  }, [userReservation]);

  const handleClose = () => {
    closeDrawer();
  };

  const startTimeMoment = userReservation
    ? moment(userReservation.startTime)
    : undefined;
  const endTimeMoment = userReservation
    ? moment(userReservation.endTime)
    : undefined;

  return (
    <div className="bonus__view">
      <div className="bonus__close-view">
        關閉
        <IconButton onClick={handleClose}>
          <CancelIcon />
        </IconButton>
      </div>

      {userReservation && (
        <Grid
          container
          direction="column"
          justifycontent="flex-start"
          alignItems="stretch"
          spacing={3}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                avatar={reservationObjectAvatar}
                title={
                  <Typography variant="h3">
                    {userReservation.reservationObj.name}
                  </Typography>
                }
                subheader={userReservation.reservationObj.reservationType.name}
              />

              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifycontent="space-around"
                  alignItems="stretch"
                  spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">預約日期</Typography>
                    <Typography variant="h6">
                      {startTimeMoment.format('YYYY-MM-DD')}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">時間</Typography>
                    <Typography variant="h6">
                      {`${startTimeMoment.format(
                        'HH:mm',
                      )} - ${endTimeMoment.format('HH:mm')}`}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">預約人數</Typography>
                    <Typography variant="h6">
                      {userReservation.reservationNumber}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">預估總價</Typography>
                    <Typography variant="h6">
                      {userReservation.totalPrice}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary">訂位時間</Typography>
                    <Typography variant="h6">
                      {userReservation.createdDate
                        ? moment(userReservation.createdDate).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar>
                    <FaceRoundedIcon />
                  </Avatar>
                }
                title={<Typography variant="h3">預約人</Typography>}
                subheader={`userID ${userReservation.userInfo.id}`}
              />

              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifycontent="space-around"
                  alignItems="stretch"
                  spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">預約姓名</Typography>
                    <Typography variant="h6">
                      {userReservation.userInfo.realName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">手機</Typography>
                    <Typography variant="h6">
                      {userReservation.userInfo.cellPhone}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary">Email</Typography>
                    <Typography variant="h6">
                      {userReservation.userInfo.email}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar>
                    <RoomRoundedIcon />
                  </Avatar>
                }
                title={<Typography variant="h3">預約物件</Typography>}
                subheader={`objectID ${userReservation.reservationObj.id}`}
              />

              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifycontent="space-around"
                  alignItems="stretch"
                  spacing={2}>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">開放時間</Typography>
                    <Typography variant="h6">
                      {userReservation.reservationObj.startTime}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">結束時間</Typography>
                    <Typography variant="h6">
                      {userReservation.reservationObj.endTime}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">目前狀態</Typography>
                    <Typography variant="h6">
                      {
                        ReservationObjectStatusText[
                          userReservation.reservationObj.status
                        ]
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">單價</Typography>
                    <Typography variant="h6">
                      {userReservation.reservationObj.price}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">可訂位人數</Typography>
                    <Typography variant="h6">
                      {userReservation.reservationObj.maxReservationNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">最少訂位人數</Typography>
                    <Typography variant="h6">
                      {userReservation.reservationObj.minReservationNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      component="p"
                      className="bonus__comment"
                      dangerouslySetInnerHTML={{
                        __html: unescape(
                          userReservation.reservationObj.description,
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default UserReservationDetail;
