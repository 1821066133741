import React, { useEffect, useState } from 'react';
import {
  Button,
  TableCell,
  TableRow,
  Chip,
  Avatar,
  Switch,
} from '@material-ui/core';
import { ReservationObjectStatus } from '../../data/common';
import { map, orderBy } from 'lodash';
import { AvatarGroup } from '@material-ui/lab';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import Brightness1RoundedIcon from '@material-ui/icons/Brightness1Rounded';
import Brightness3RoundedIcon from '@material-ui/icons/Brightness3Rounded';
import BurstModeRoundedIcon from '@material-ui/icons/BurstModeRounded';

const ReservationObjectTableRow = (props) => {
  const {
    reservationObject,
    toggleStatus,
    toEditReservationObject,
    openImgSettingModal,
  } = props;
  const [avatars, setAvatars] = useState();

  useEffect(() => {
    if (
      reservationObject.reservationImageList &&
      reservationObject.reservationImageList.length > 0
    ) {
      const newImageList = orderBy(
        reservationObject.reservationImageList,
        (reservationImage) => reservationImage.isCover,
        'desc',
      );

      setAvatars(newImageList);
    } else {
      setAvatars(undefined);
    }
  }, [reservationObject]);

  const handleToggleStatus = () => {
    toggleStatus(
      reservationObject.id,
      reservationObject.status === ReservationObjectStatus.ONLINE
        ? ReservationObjectStatus.OFFLINE
        : ReservationObjectStatus.ONLINE,
    );
  };

  const handleEdit = () => {
    toEditReservationObject(reservationObject.id);
  };

  const handleImgSetting = () => {
    openImgSettingModal(reservationObject);
  };

  return (
    <TableRow>
      <TableCell component="th">
        {!!avatars && (
          <AvatarGroup max={3}>
            {map(avatars, (avatar) => (
              <Avatar
                key={avatar.imageId}
                alt={avatar.isCover ? '封面' : '圖片'}
                src={avatar.imageUrl}
              />
            ))}
          </AvatarGroup>
        )}
      </TableCell>
      <TableCell component="th">{reservationObject.id}</TableCell>
      <TableCell>{reservationObject.name}</TableCell>
      <TableCell>{reservationObject.reservationType.name}</TableCell>
      <TableCell>
        {`${reservationObject.startTime.substring(
          0,
          5,
        )} ~ ${reservationObject.endTime.substring(0, 5)}`}
      </TableCell>
      <TableCell>
        <Chip
          variant="outlined"
          size="small"
          icon={<AttachMoneyRoundedIcon />}
          label={`${reservationObject.price}`}
        />
      </TableCell>
      <TableCell>
        <Chip
          variant="outlined"
          size="small"
          icon={<Brightness1RoundedIcon />}
          label={`${reservationObject.maxReservationNumber} 人`}
        />
      </TableCell>
      <TableCell>
        <Chip
          variant="outlined"
          color="secondary"
          size="small"
          icon={<Brightness3RoundedIcon />}
          label={`${reservationObject.minReservationNumber} 人`}
        />
      </TableCell>
      <TableCell>
        <Switch
          checked={reservationObject.status === ReservationObjectStatus.ONLINE}
          onChange={handleToggleStatus}
          color="primary"
        />
      </TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<BurstModeRoundedIcon />}
          style={{ marginRight: '12px' }}
          onClick={handleImgSetting}>
          圖片設定
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<EditRoundedIcon />}
          onClick={handleEdit}>
          編輯
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ReservationObjectTableRow;
